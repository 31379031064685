import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first, firstValueFrom, lastValueFrom } from 'rxjs';
import { RatesService } from '../../../services/api/rates.service';
import { AdditionalServicesService } from '../../../services/api/additional-services';

@Component({
  selector: 'lib-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceModalComponent implements OnInit {
  serviceForm: FormGroup;
  currentRates: any
  exchangeRate: number = 1;
  convertedValue: number = 0;
  action: string = ''

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private ratesService: RatesService,
    private additionalServicesService: AdditionalServicesService,
    private dialogRef: MatDialogRef<ServiceModalComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public data: { bookingId: string, service: any, readOnly: boolean }
  ) { 
    this.serviceForm = this.fb.group({
      name: ['', [Validators.required]],
      price: [null, [Validators.required, Validators.min(0)]],
      currency: ['EUR', [Validators.required]],
      description: [''],
    });
  }

  ngOnInit(): void {
    this.action = !this.data.service ? 'Create' : (this.data.readOnly ? 'View' : 'Edit')

    if (this.data.service) {
      this.serviceForm.patchValue({
        name: this.data.service.title || '',
        price: isNaN(this.data.service.price) ? null : this.data.service.price,
        currency: this.data.service.currency || 'EUR',
        description: this.data.service.description || '',
      });
    }

    this.serviceForm.get('currency')?.valueChanges.subscribe((currency) => {
      this.updateExchangeRate(currency);
    });

    this.serviceForm.get('price')?.valueChanges.subscribe((price) => {
      this.updateConvertedValue(price);
    });

    this.getExchangeRates();
  }

  updateExchangeRate(currency: string): void {
    this.exchangeRate = this.currentRates[currency] / 100;
    this.updateConvertedValue(this.serviceForm.get('price')?.value)

    this.cd.detectChanges();
  };

  getExchangeRates(): void {
    this.ratesService.getCurrentRates().pipe(first()).subscribe(result => {
      this.currentRates = result
      this.updateExchangeRate('EUR')
    });
  }

  updateConvertedValue(price: number): void {
    this.convertedValue = Number((price || 0 / this.exchangeRate).toFixed(2))
  }

  async onSaveButton() {
    try {
      await this.saveAdditionalService()
      this.dialogRef.close(true)
    } catch (error) {
      this.dialogRef.close(false)
    }
  }

  saveAdditionalService(): Promise<any> {
    const formValues = this.serviceForm.value
  
    const service = {
      bookingId: this.data.bookingId,
      title: formValues.name,
      description: formValues.description,
      price: formValues.price,
      currency: formValues.currency
    }
  
    const observable = this.data.service
      ? this.additionalServicesService.editService(this.data.service.id, service).pipe(first())
      : this.additionalServicesService.createService(service).pipe(first())
  
    return firstValueFrom(observable);
  }

  async chargeAdditionalService() {
    try {
      const result = await this.saveAdditionalService()

      const serviceId = result?.id || this.data.service?.id
      if (serviceId) {
        await firstValueFrom(this.additionalServicesService.chargeService(serviceId).pipe(first()))
        this.dialogRef.close(true)
      }
    } catch (error) {
      this.dialogRef.close(false)
    }
  }
}