<div class="modal-header">
	<span class="modal-header__text">Cancel and refund service</span>
	<button mat-icon-button mat-dialog-close>
		<img src="assets/img/cancel.svg" alt="">
	</button>
</div>
<form [formGroup]="refundForm" class="modal-container">
  <p>You want to cancel an already paid services.</p>

  <div class="service-info">
    <div class="d-f fd-c">
      <span class="service-label">Service name</span>
      <span class="service-name">{{ data.service.title }}</span>
    </div>
    <div class="token-display">
      <img src="assets/img/opes-coin.svg" alt="">
      <span class="opes-coin-rate">{{ data.service.tokens }}</span>
    </div>
  </div>

  <p>Specify the amount of tokens you want to return to the account after cancellation.</p>

  <div class="d-f">
    <img src="assets/img/opes-coin.svg" alt="">
    <mat-form-field appearance="outline" class="refund-input">
      <input matInput type="number" formControlName="refundAmount" required>
    </mat-form-field>
  </div>
</form>

<div class="modal-bottom">
  <button mat-stroked-button class="modal-bottom__btn cancel" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="accent" class="modal-bottom__btn ml-10" [disabled]="refundForm.invalid" (click)="processRefund()">OK</button>
</div>
