<mat-expansion-panel class="widget mat-elevation-z request-menu" hideToggle [expanded]="items">
  <mat-expansion-panel-header class="widget__header">
    <mat-panel-title>
      <div class="expand-icon" [class._active]="false">
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 4.5L0.250001 8.39712L0.250001 0.602886L7 4.5Z" fill="white" />
        </svg>
      </div>
      <div class="title">Check list</div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="description" >
        <span class="description__first" *ngIf="items" (click)="$event.stopPropagation(); openChecklist()">Edit</span>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="widget__body">
    <ng-container *ngIf="items; else empty">
      <div *ngFor="let item of items | filterArray : filterItems" class="d-f ai-c jc-sb">
        <mat-checkbox color="primary" [checked]="item.isChecked" (change)="item.isChecked=$event.checked; updateChecklist()" labelPosition="before" class="checkbox">
          <span class="checkbox-label" [class.checked]="item.isChecked">{{ item.name }}</span>
        </mat-checkbox>
      </div>
    </ng-container>
    <ng-template #empty>
      <button class="create" mat-stroked-button color="accent" (click)="createChecklistFromDefault()">Create checklist from template</button>
    </ng-template>
  </div>
</mat-expansion-panel>
