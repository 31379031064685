<div class="modal-header">
	<span class="modal-header__text">{{ action }} Service</span>
	<button mat-icon-button mat-dialog-close>
		<img src="assets/img/cancel.svg" alt="">
	</button>
</div>
<form [formGroup]="serviceForm" class="modal-container">
	<mat-form-field appearance="outline">
		<mat-label>Service name</mat-label>
		<input matInput formControlName="name" [readonly]="data.readOnly" />
	</mat-form-field>

  <div class="d-f ai-c fg-1 jc-sb">
    <div>
      <mat-form-field class="budget-input mr-10" appearance="outline">
        <input matInput type="number" step="1" min="0" formControlName="price" placeholder="Price" required [readonly]="data.readOnly">
      </mat-form-field>
      <mat-form-field class="currency" appearance="outline">
        <mat-select formControlName="currency" [disabled]="data.readOnly">
          <mat-option value="USD">USD</mat-option>
          <mat-option value="EUR">EUR</mat-option>
          <mat-option value="GBP">GBP</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <span class="opes-coin-rate">=</span>

    <div>
      <img class="mr-5" src="assets/img/opes-coin.svg" alt="">
      <span class="opes-coin-rate">{{ convertedValue }}</span>
    </div>
  </div>

  <div>
    <mat-label class="rate-label">Exchange rate on the platform</mat-label>
    <div>
      <img class="mr-5" src="assets/img/opes-coin.svg" alt="">
      <span class="rate">1 = {{ exchangeRate }} {{ serviceForm.get('currency')?.value | uppercase }}</span>
    </div>
  </div>

  <div class="mt-10">
    <label>Description</label>
    <mat-form-field class="wp-100" appearance="outline">
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="10" cdkAutosizeMaxRows="10" formControlName="description" [readonly]="data.readOnly"></textarea>
    </mat-form-field>
  </div>
</form>
<div class="modal-bottom" *ngIf="!data.readOnly">
	<button mat-stroked-button class="modal-bottom__btn d-f jc-c ai-c cancel" mat-dialog-close>Cancel</button>
	<button mat-stroked-button class="modal-bottom__btn d-f jc-c ai-c ml-10 cancel" [disabled]="!serviceForm.valid" (click)="onSaveButton()">Save</button>
  <button mat-raised-button color="accent" class="modal-bottom__btn d-f jc-c ai-c ml-10" [disabled]="!serviceForm.valid" (click)="chargeAdditionalService()">Charge</button>
</div>
