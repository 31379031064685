import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first, firstValueFrom } from 'rxjs';
import { AdditionalServicesService } from '../../../services/api/additional-services';

@Component({
  selector: 'app-refund-service-modal',
  templateUrl: './refund-service-modal.component.html',
  styleUrls: ['./refund-service-modal.component.scss']
})
export class RefundServiceModalComponent implements OnInit {
  refundForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RefundServiceModalComponent>,
    private additionalServicesService: AdditionalServicesService,
    @Inject(MAT_DIALOG_DATA) public data: { service: any }
  ) {
    this.refundForm = this.fb.group({
      refundAmount: [this.data.service.tokens, [Validators.required, Validators.min(0), Validators.max(this.data.service.tokens)]]
    });
  }

  ngOnInit(): void {
    console.log('this.data', this.data)
  }

  async processRefund() {
    if (this.refundForm.valid) {
      const refundData = this.refundForm.value;
      const refundAmount = refundData.refundAmount !== this.data.service.tokens ? refundData.refundAmount : null
      await firstValueFrom(this.additionalServicesService.cancelService(this.data.service.id, refundAmount).pipe(first()));

      this.dialogRef.close(true);
    }
  }
}